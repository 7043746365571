import type { Session } from '../types/session'

export const LEGACY_TOKEN_KEY = 'customerToken'
export const SESSION_CACHE_KEY = 'SESSION_CACHE'

export const TOKEN_KEY = 'token'
export const EXTERNAL_ID_KEY = 'externalId'
export const EMAIL_KEY = 'email'
export const HASHED_EMAIL_KEY = 'hashedEmail'
export const NAME_KEY = 'name'
export const SURNAME_KEY = 'surname'
export const LOGGED_KEY = 'logged'
export const ACCOUNT_ID_KEY = 'accountId'

// 12hours on miliseconds
export const DIFF_TIMESTAMP = 43200000

export const baseSession: Session = {
	accountId: null,
	externalId: null,
	email: null,
	hashedEmail: null,
	name: null,
	surname: null,
	logged: false,
	token: null,
	isReady: false,
}
